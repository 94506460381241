import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  FormControl,
  TextField,
  Input,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NetworkHandler from 'src/utils/NetworkHandler';

export default function AddcategoryPage() {
  const [formData, setFormData] = useState({
    category_name: '',
    description: '',
  });

  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.type === 'image/svg+xml') {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      event.target.value = null;
      setFile(null);
      alert('Please select an SVG image.');
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formDataUpload = new FormData();
      formDataUpload.append('category_name', formData.category_name);
      formDataUpload.append('description', formData.description);
      formDataUpload.append('images', file);


      const apiUrl =   
        'https://khadi-backend.gitdr.com/api/categories/createCategory';
      const response = await axios.post(apiUrl, formDataUpload);

      console.log(response.data);
     
      alert('Category created successfully!');
      navigate('/dashboard/category');
     
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create category. Please try again later.');
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Add Category | Kadhi </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add Category
          </Typography>
        </Stack>

        <Card sx={{ minWidth: 900 }}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: '30px' }}>
                <FormControl fullWidth>
                  <TextField
                    id="category_name"
                    name="category_name"
                    label="Category"
                    value={formData.category_name}
                    onChange={handleChange}
                    placeholder="Category"
                    fullWidth
                    required
                  />
                </FormControl>
              </div>
              <div style={{ marginBottom: '30px' }}>
                <FormControl fullWidth>
                  <Typography variant="h6">Upload Category Image (Upload svg image only)</Typography>
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    required
                    label="Upload Image"
                    accept="image/svg+xml"
                    onChange={handleFileChange}
                  />
                </FormControl>
              </div>
              {previewImage && (
                <div style={{ marginBottom: '30px' }}>
                  <img src={previewImage} alt="Image Preview" style={{ maxWidth: '200px' }} />
                </div>
              )}
              <div style={{ marginBottom: '30px' }}>
                <FormControl fullWidth>
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder=" Description"
                    fullWidth
                    multiline
                    rows={5}
                    required
                  />
                </FormControl>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  onClick={() => navigate('/dashboard/category')}
                  type="button"
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
