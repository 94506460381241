import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Typography, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { refreshAccessToken } from './RefreshToken';


// const StyledRoot = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex',
//   },
// }));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    const auth = btoa(`${username}:${password}`);
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `${auth}`,
    });

    try {
      const response = await fetch('https://khadi-backend.gitdr.com/api/users/Adminlogin/', {
        method: 'POST',
        headers,
        body: JSON.stringify({ email: username, password }),
      });

      if (response.ok) {
        setLoginError('');

        const data = await response.json();
        const { accessToken, user } = data;
        // Store data in sessionStorage
        sessionStorage.setItem('accessToken', JSON.stringify(accessToken));

        sessionStorage.setItem('userName', user.user_name);
        sessionStorage.setItem('email', user.email);

        navigate('/dashboard');
      } else if (response.status === 401) {
        await refreshAccessToken();
      } else {
        const errorData = await response.json();
        setLoginError(errorData.message || 'Login failed.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setLoginError('An error occurred during login.');
    }
  };

 
  return (
    <>
      <Helmet>
        <title> Login | Kadhi</title>
      </Helmet>

      {/* <StyledRoot> */}
      <Container maxWidth="sm">
        <StyledContent>
          <Typography variant="h4" gutterBottom>
            Sign in to Kadhi
          </Typography>

          <form onSubmit={handleLogin}>
            <TextField
              fullWidth
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
              margin="normal"
              required
            />

            <TextField
              fullWidth
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              variant="outlined"
              margin="normal"
              required
            />

            {loginError && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {loginError}
              </Typography>
            )}

            <Button type="submit" fullWidth variant="contained" size="large" sx={{ mt: 3 }}>
              Login
            </Button>
          </form>
        </StyledContent>
      </Container>
      {/* </StyledRoot> */}
    </>
  );
};

export default LoginPage;
