import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Box,
  Card,
  Container,
  Stack,
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  CardContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
export default function BannerAdd() {
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files);
    setSelectedImages(imagesArray);
  };

  const handleAddIconClick = () => {
    fileInputRef.current.click();
  };

  const [expiryDateValue, setExpiryDateValue] = useState('');
  const handleExpiryDateChange = (event) => {
    setExpiryDateValue(event.target.value);
  };

  const [selectedRadio, setSelectedRadio] = useState('category');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          'https://khadi-backend.gitdr.com/api/categories/'
        );
        setCategories(response.data.categories);
      } catch (error) {
        console.log('Error fetching categories:', error);
      }
    };

    const fetchSubcategories = async (categoryId) => {
      try {
        const response = await axios.get(
          `https://khadi-backend.gitdr.com/api/subcategories/categories/${categoryId}`
        );
        setSubcategories(response.data);
      } catch (error) {
        console.log('Error fetching subcategories:', error);
      }
    };

    const fetchProducts = async (subcategoryId) => {
      try {
        const response = await axios.get(
          `https://khadi-backend.gitdr.com/api/products/subcategories/${subcategoryId}`
        );
        setProducts(response.data);
      } catch (error) {
        console.log('Error fetching products:', error);
      }
    };

    fetchCategories();
    if (selectedRadio === 'category') {
      fetchSubcategories(selectedCategory);
    } else if (selectedRadio === 'products') {
      fetchSubcategories(selectedCategory);
      fetchProducts(selectedSubcategory);
    }
  }, [selectedCategory, selectedSubcategory, selectedRadio]);


  const resetForm = () => {
    setSelectedImages([]);
    setExpiryDateValue('');
    setSelectedRadio('category');
    setSelectedCategory('');
    setSelectedSubcategory('');
    setSelectedProduct('');
  };
  



  const fetchData = async () => {
    try {
      if (selectedImages.length === 0) {
        alert('Please Select Atleast One Image');
        return;
      }

      if (!expiryDateValue.trim()) {
        alert('Expiry Date field cannot be empty');
        return;
      }

      let link =
        selectedRadio === 'category'
          ? `Category/${selectedCategory}`
          : `Products/${selectedSubcategory}/${selectedProduct}`;

      const formData = new FormData();
      selectedImages.forEach((images) => {
        formData.append('images', images);
      });
      formData.append('link', link);
      formData.append('expire_date', expiryDateValue);

      const response = await axios.post(
        'https://khadi-backend.gitdr.com/api/banners/createBanner',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      console.log('Server Response:', response.data);
      alert('Success');
      resetForm();
      navigate('/dashboard/banner');
    } catch (error) {
      console.log('Error sending data to server:', error);
      if (error.response) {
        console.log('Server Response:', error.response.data);
      }
    }
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Add Banners
        </Typography>
      </Stack>
      <Card sx={{ borderRadius: '5px', padding: '0px 75px 50px 75px' }}>
        <CardContent>
          <form>
            <Box
              sx={{
                border: '1px solid #DDDEDF',
                padding: '50px 50px 50px 50px',
                margin: '40px 400px 0px 0px',
                textAlign: 'center',
                borderRadius: '5px',
              }}
            >
              <Typography htmlFor="file-input">
                <AddIcon
                  sx={{ fontSize: '100px', color: '#DDDEDF', cursor: 'pointer' }}
                  onClick={handleAddIconClick}
                  placeholder="Click here"
                />
              </Typography>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept="images/*"
                multiple
              />
              {selectedImages.map((images, index) => (
                <Box key={index}>
                  <img
                    src={URL.createObjectURL(images)}
                    alt={`Preview ${index + 1}`}
                    style={{ maxWidth: '300px' }}
                  />
                </Box>
              ))}
            </Box>
            <Typography sx={{ fontWeight: '700', fontSize: '15px', fontFamily: 'Plus Jakarta Sans' }}>
              370 X 177
            </Typography>
            <FormControl>
              <FormLabel
                sx={{
                  fontWeight: '500',
                  fontSize: '15px',
                  fontFamily: 'Plus Jakarta Sans',
                  color: 'gray',
                  letterSpacing: '0.75px',
                  marginTop: '50px',
                }}
                id="demo-row-radio-buttons-group-label"
              >
                Banner points to
              </FormLabel>
              <RadioGroup
                value={selectedRadio}
                onChange={(e) => setSelectedRadio(e.target.value)}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="category" control={<Radio />} label="Category" />
                <FormControlLabel value="products" control={<Radio />} label="Products" />
              </RadioGroup>
            </FormControl>
            {selectedRadio === 'category' && (
              <Box sx={{ marginTop: '20px' }}>
                <FormControl variant="outlined" sx={{ width: '300px' }}>
                  <InputLabel>Select Category</InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    label="Select Category"
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.category_id}>
                        {category.category_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedCategory && (
                  <FormControl variant="outlined" sx={{ width: '300px', marginLeft: '50px' }}>
                    <InputLabel>Select SubCategory</InputLabel>
                    <Select
                      value={selectedSubcategory}
                      onChange={(e) => setSelectedSubcategory(e.target.value)}
                      label="Select SubCategory"
                    >
                      {subcategories.map((subcategory) => (
                        <MenuItem key={subcategory.id} value={subcategory.subcategory_id}>
                          {subcategory.subcategory_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            )}
            {selectedRadio === 'products' && (
              <Box sx={{ marginTop: '20px' }}>
                <FormControl variant="outlined" sx={{ width: '300px' }}>
                  <InputLabel>Select Category</InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    label="Select Category"
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.category_id}>
                        {category.category_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedCategory && (
                  <FormControl variant="outlined" sx={{ width: '300px', marginLeft: '27px' }}>
                    <InputLabel>Select SubCategory</InputLabel>
                    <Select
                      value={selectedSubcategory}
                      onChange={(e) => setSelectedSubcategory(e.target.value)}
                      label="Select SubCategory"
                    >
                      {subcategories.map((subcategory) => (
                        <MenuItem key={subcategory.id} value={subcategory.subcategory_id}>
                          {subcategory.subcategory_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {selectedSubcategory && (
                  <FormControl variant="outlined" sx={{ width: '300px', marginLeft: '27px' }}>
                    <InputLabel>Select Product</InputLabel>
                    <Select
                      value={selectedProduct}
                      onChange={(e) => setSelectedProduct(e.target.value)}
                      label="Select Product"
                    >
                      {products.map((product) => (
                        <MenuItem key={product.id} value={product.product_id}>
                          {product.product_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
              <Box sx={{ marginTop: '-20px' }}>
                <Typography sx={{ color: 'gray', fontWeight: '500', fontSize: '15px', fontFamily: 'Plus Jakarta Sans' }}>
                  Expiry Date
                </Typography>
                <TextField type="date" sx={{ width: '300px' }} value={expiryDateValue} onChange={handleExpiryDateChange} />
              </Box>
            </Box>
            <Button
              onClick={fetchData}
              sx={{
                backgroundColor: '#0FC5FF',
                color: '#fff',
                borderRadius: '5px',
                padding: '5px 45px 5px 45px',
                float: 'right',
                marginTop: '40px',
                ':hover': {
                  bgcolor: '#2065D1',
                  color: 'white',
                },
              }}
            >
              Save
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}
