import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Button,
  Popover,
  MenuItem,
  IconButton,
  Container,
  Stack,
  Typography,
  Card,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardContent,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import Scrollbar from '../components/scrollbar';

// @mui

// components
// import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// // mock
// import PRODUCTS from '../_mock/products';
import Iconify from '../components/iconify';
import NetworkHandler from 'src/utils/NetworkHandler';

export default function ProductsPage() {
  //const [openFilter, setOpenFilter] = useState(false);
  //const [showAddProduct, setShowAddProduct] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const handleOpenMenu = (event, productId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(productId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const toggleStatus = async (productToUpdate) => {
    try {
      productToUpdate.active = !productToUpdate.active;
      await NetworkHandler.makePutRequest(`products/${productToUpdate.product_id}`);
      setRows(() => rows.map((row) => (row.product_id === productToUpdate.productId ? productToUpdate : row)));

      alert('Active status updated successfully!');
    } catch (error) {
      console.error('Failed to update status:', error);
      alert('Failed to update status. Please try again later.');
    }
  };

  const handleToggleClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        const allProducts = [];
        const response = await NetworkHandler.makeGetRequest(`products/?page=${page + 1}&pagesize=${rowsPerPage}`);
        const products = response.data.data.productsWithDetails;  
        allProducts.push(...products);
        setPageCount(response.data.totalPages)
        setRows(response.data.totalPages * rowsPerPage)
        setUserPosts(allProducts);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchData();
  },[page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteClick = (productId) => {
    setItemToDelete(productId);
    setDeleteDialogOpen(true);
    handleCloseMenu();
  };

  const handleDeleteConfirmation = async () => {
    try {

      await NetworkHandler.makeDeleteRequest(`products/${itemToDelete}`)

      setRows((prevRows) => prevRows.filter((row) => row.product_id !== itemToDelete));

      alert('Product deleted successfully!');
    } catch (error) {
      console.error('Failed to delete Product:', error);
      alert('Failed to delete product. Please try again later.');
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleCancelConfirmation = () => {
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <>
      <Helmet>
        <title> Products </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Products
        </Typography>

        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ float: 'right' }}
          onClick={() => navigate('/dashboard/ProductsPage/AddProduct')}
        >
          Add Product
        </Button>

        {/*       
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack> */}

        {/* <ProductList products={PRODUCTS} />
        <ProductCartWidget /> */}

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}></Stack>

        {/* order */}
        <Scrollbar>
          <Card sx={{ minWidth: 900 }}>
            <CardContent>
              {isLoading ? ( // Display the loader while data is being fetched
                <CircularProgress />
              ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">ID</TableCell>
                          <TableCell align="left">Product Name</TableCell>
                          <TableCell align="left">Status</TableCell>
                          <TableCell align="left"> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userPosts.map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.product_id}
                              onClick={() => navigate(`/dashboard/ProductsPage/ProductDetails/${row.product_id}`)}
                              style={{ cursor: 'pointer' }}
                            >
                              <TableCell align="left">{row.product_id}</TableCell>
                              <TableCell align="left">{row.product_name}</TableCell>
                              <TableCell align="left">
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={row.active}
                                        onChange={(event) => toggleStatus(row)}
                                        onClick={(event) => handleToggleClick(event)}
                                        color="primary"
                                      />
                                    }
                                    label={row.active ? 'Active' : 'Inactive'}
                                  />
                                </div>
                              </TableCell>
                              {/* <TableCell align="left">{row.link}</TableCell>

                                <TableCell align="left">{formatDate(row.expire_date)}</TableCell> */}
                              <TableCell align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => handleOpenMenu(event, row.product_id)}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}
            </CardContent>
          </Card>
        </Scrollbar>
      </Container>
      <Popover
        open={Boolean(anchorEl) && selectedRow !== null}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem component={Link} to={`/dashboard/Products/EditProductPage/${selectedRow}`}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            handleDeleteClick(selectedRow);
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Dialog open={isDeleteDialogOpen} onClose={handleCancelConfirmation}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this Product?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
