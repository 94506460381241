import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import CategoryPage from './pages/CategoryPage';
import SubcategoryPage from './pages/SubcategoryPage';
import AddSubcategoryPage from './pages/AddSubcategoryPage';
import EditSubcategoryPage from './pages/EditSubcategoryPage';
import OrderPage from './pages/OrderPage';

//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import OrderDetailsPage from './pages/OrderDetailsPage';
//import BlogPage from './pages/BlogPage';

import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import ProductDetails from './pages/ProductDetails';
import VariantAddPage from './pages/VariantAddPage';
import ProductviewDetailsPage from './pages/ProductviewDetailsPage';
import AddProduct from './pages/AddProduct'; 

import VariantsPage from './pages/VariantsPage'; 
import VariantsEdit from './pages/VariantsEdit';

import BannerPage from './pages/BannerPage';


import BannerAdd from './pages/BannerAdd';
import BannerEdit from './pages/BannerEdit';

import DashboardAppPage from './pages/DashboardAppPage';
import AddcategoryPage from './pages/AddcategoryPage';
import EditcategoryPage from './pages/EditcategoryPage';
import AddproductPage from './pages/AddproductPage';

// import { useParams } from 'react-router-dom';
// import { Pages } from '@mui/icons-material';


// import BannerPage from './pages/BannerPage'; // Import your Banner component
// import BannerEdit from './components/BannerEdit'; // Imp

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'dashboard/app', element: <DashboardAppPage /> },


        { path: 'ProductsPage/AddProduct', element: <AddProduct /> },
        { path: 'productsPage/ProductDetails/:product_id', element: <ProductDetails /> },
        { path: 'productsPage/ProductDetails/VariantAddPage/:product_id', element: <VariantAddPage /> },
        { path: 'productsPage/ProductDetails/ProductviewDetailsPage/:variant_id', element: <ProductviewDetailsPage /> },
        //{ path: '/dashboard/ProductsPage/ProductDetails/VariantsEdit/:variant_id', element: <VariantsEdit /> },
        { path: 'ProductsPage/AddProduct/VariantsPage/:product_id', element: <VariantsPage /> },

        { path: 'products/addproduct', element: <AddproductPage/> },

        { path: 'order', element: <OrderPage /> },
        { path: 'order/:orderId',element: <OrderDetailsPage />},
        { path: 'category', element: <CategoryPage /> },
        { path: 'category/addcategory', element: <AddcategoryPage /> },
        { path: 'category/editcategory/:categoryid', element: <EditcategoryPage /> },
        { path: 'subcategory', element: <SubcategoryPage /> },
        { path: 'Subcategory/AddSubcategoryPage', element: <AddSubcategoryPage />,},
        { path: 'Subcategory/EditSubcategoryPage/:subcategoryid', element: <EditSubcategoryPage />,},
        { path: 'banner', element: <BannerPage /> },
        { path: 'subcategory', element: <SubcategoryPage /> },
        { path: 'BannerPage/BannerAdd', element: <BannerAdd /> },
        { path: 'BannerPage/BannerEdit/:bannerId', element: <BannerEdit /> },
        { path: 'VariantsPage/VariantsEdit/:variantId', element: <VariantsEdit /> },
        { path: 'subcategory', element: <SubcategoryPage /> },
        

      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  // <Route exact path="/" component={BannerPage} />
  // <Route exact path="/edit" component={BannerEdit} />

  return routes;
}
