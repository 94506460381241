import { Helmet } from 'react-helmet-async';
import {
  Popover,
  MenuItem,
  IconButton,
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Card,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardContent,
  TableContainer,
  TablePagination,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import NetworkHandler from 'src/utils/NetworkHandler';

export default function SubcategoryPage() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const handleOpenMenu = (event, subcategoryId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(subcategoryId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

 
  useEffect(() => {
    const fetchData = async () => {
      try {
       
        let currentPage = 1;
        const allSubcategory = [];
        while (true) {
        
          
          const response = await NetworkHandler.makeGetRequest(`subcategories?page=${currentPage}&pagesize=10`);

          const subcategory = response.data.data.subcategory;

          if (subcategory.length === 0) {
            break;
          }
          allSubcategory.push(...subcategory);
          currentPage++;
        }
        setRows(allSubcategory);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteClick = (subcategoryId) => {
    setItemToDelete(subcategoryId);
    setDeleteDialogOpen(true);
    handleCloseMenu();
  };

  const handleDeleteConfirmation = async () => {
    try {
      // const apiUrl = `https://khadi-backend.gitdr.com/api/subcategories/${itemToDelete}`;
      // await axios.delete(apiUrl);

      await NetworkHandler.makeDeleteRequest(`subcategories/${itemToDelete}`)


      setRows((prevRows) => prevRows.filter((row) => row.subcategory_id !== itemToDelete));

      alert('Subcategory deleted successfully!');
    } catch (error) {
      console.error('Failed to delete Subcategory:', error);
      alert('Failed to delete category. Please try again later.');
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleCancelConfirmation = () => {
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Sub Category | Kadhi </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sub Category
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            sx={{ float: 'right' }}
            onClick={() => navigate('/dashboard/Subcategory/AddSubcategoryPage')}
          >
            New Sub Category
          </Button>
        </Stack>

        <Scrollbar>
          <Card sx={{ minWidth: 900 }}>
            <CardContent>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">ID</TableCell>
                          <TableCell align="left">sub Category</TableCell>
                          <TableCell align="left">Category</TableCell>
                          <TableCell align="left"> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.subcategory_id}>
                              <TableCell align="left">{row.subcategory_id}</TableCell>
                              <TableCell align="left">{row.subcategory_name}</TableCell>
                              <TableCell align="left">{row.Category.category_name}</TableCell>
                              <TableCell align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => handleOpenMenu(event, row.subcategory_id)}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}
            </CardContent>
          </Card>
        </Scrollbar>
      </Container>
      <Popover
        open={Boolean(anchorEl) && selectedRow !== null}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem component={Link} to={`/dashboard/Subcategory/EditSubcategoryPage/${selectedRow}`}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            handleDeleteClick(selectedRow);
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Dialog open={isDeleteDialogOpen} onClose={handleCancelConfirmation}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this category?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
