import { Helmet } from 'react-helmet-async';
// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import NetworkHandler from 'src/utils/NetworkHandler';
import { useNavigate } from 'react-router-dom';


export default function OrderDetailsPage() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState();
  const [billing, setBilling] = useState();
  const [shipping, setShipping] = useState();
  const [orderDate, setOrderDate] = useState();
  const [total, setTotal] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [selectedValue, setSelectedValue] = useState('');
  const [trackId, setTrackId] = useState('');
  const [showTextBox, setShowTextBox] = useState();
  const [isInvoiceVisible, setIsInvoiceVisible] = useState(false);
  const { orderId } = useParams();
  const navigate = useNavigate();
  const componentRef = useRef();

  const handleChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);

    // Check if the selected option is "option2" to show the text box
    setShowTextBox(selectedOption === 'Shipped');
    console.log('Selected Value:', selectedOption);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await NetworkHandler.makeGetRequest(`/orders/${orderId}`);
      console.log(response.data);
      setData(response.data.order.Orderdetails);
      setName(response.data.order.User);
      setOrderDate(response.data.order);
      setBilling(response.data.order);
      setShipping(response.data.order);
      setPaymentMethod(response.data.order);
      setTotal(response.data.order);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };


  const updateStatus = async () => {
    try {
      const apiEndpoint = `https://khadi-backend.gitdr.com/api/orders/${orderId}`;

      // Assuming your API expects a JSON body with the 'status' property
      const requestBody = {
        status: selectedValue,
        tracking_number: trackId,
      };

      // Send the API request using the fetch API with async/await
      const response = await fetch(apiEndpoint, {
        method: 'PUT', // Or 'POST' depending on your API
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      if (selectedValue === '') {
        alert('Please Update Status');
      } else {
        const data = await response.json();
        // Handle the response data if needed
        //console.log('Status updated successfully:', data);
        alert('status updated');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  useEffect(() => {}, [selectedValue, trackId]);
  // const componentRef = useRef();

  const handleCancel = () => {
    navigate('/dashboard/order');
  };

  return (
    <>
      <Helmet>
        <title> Order Details</title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom>
          OrderDetails
        </Typography>

        <Card sx={{ p: 8 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <CardContent>
              <Box width="auto" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box>
                  <Typography gutterBottom variant="h9" sx={{ color: 'gray', fontSize: '16px' }}>
                    Name
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '18px', marginBottom: '20px', fontWeight: 400 }}
                  >
                    {name.user_name}
                  </Typography>

                  <Typography sx={{ marginBottom: '20px', color: 'gray', fontSize: '16px' }}>
                    Billing Address
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '18px', marginBottom: '20px', fontWeight: 400 }}
                  >
                    {billing.billingAddress}
                  </Typography>
                  <Typography gutterBottom variant="h7" sx={{ lineHeight: 5, color: 'gray' }}>
                    Status
                    <br />
                  </Typography>

                  <FormControl sx={{ width: '300px' }}>
                    <TextField label="select status" fullWidth select value={selectedValue} onChange={handleChange}>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="cancelled">cancelled</MenuItem>
                      <MenuItem value="Processing">Processing</MenuItem>
                      <MenuItem value="Shipped">Shipped</MenuItem>
                      <MenuItem value="Delivered">Delivered</MenuItem>
                    </TextField>
                  </FormControl>
                </Box>
                <Box>
                  <Box>
                    <Typography gutterBottom variant="h9" sx={{ color: 'gray' }}>
                    Date: {new Date(orderDate.order_date).toLocaleDateString()}
                    </Typography>
                    <Typography
                     
                      sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '18px', color: 'gray', marginBottom: '20px' }}
                    >
                      Id :{orderId}
                    </Typography>
                  </Box>
                  <Typography sx={{ marginBottom: '20px', fontSize: '16px', color: 'gray' }}>
                    Shipping Address
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '18px', marginBottom: '20px', fontWeight: 400 }}
                  >
                    {shipping.shippingAddress}
                  </Typography>

                  {showTextBox && (
                    <TextField
                      onChange={(e) => setTrackId(e.target.value)}
                      value={trackId}
                      label=" Shipping Track Id"
                      variant="outlined"
                      sx={{ marginTop: '75px' }}
                      style={{ width: '300px' }}
                    />
                  )}
                </Box>
              </Box>
              <Typography
                variant="h4"
                sx={{ color: '#00000080', fontFamily: 'Plus Jakarta Sans', fontWeight: '600', lineHeight: 4 }}
              >
                Order Details
              </Typography>
          
              <TableContainer>
                <Table sx={{ minWidth: 100, border: '1px solid #f0f0f0' }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Id</TableCell>
                      <TableCell align="center">Product Name</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Price</TableCell>
                      <TableCell align="center">Subtotal</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                {data && Array.isArray(data) && data.length > 0 ? (
                  data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center" component="th" scope="row">
                      {row.Variant.sku}
                    </TableCell>
                    <TableCell align="center">{row.Variant.Product.product_name}</TableCell>
                    <TableCell align="center">{row.quantity}</TableCell>
                    <TableCell align="center">{row.Variant.price}</TableCell>
                    <TableCell align="center">{row.subtotal}</TableCell>
                  </TableRow>
                ))
              ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No order details available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>

                </Table>
              </TableContainer>
               
                
              
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box>
                  {/* <Typography
                    sx={{
                      color: '#00000066',
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '500',
                      fontSize: '15px',
                      marginTop: '30px',
                    }}
                  >
                    Payment Method
                  </Typography> */}

                  {/* <Typography
                    sx={{
                      color: '#17dd19',
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '500',
                      fontSize: '15px',
                      marginTop: '30px',
                      border: '1px solid #c4c5c4',
                      padding: '5px 20px 5px 20px',
                      borderRadius: '5px',
                      width: '162px',
                      textAlign: 'center',
                    }}
                  >
                    {paymentMethod.payment_method}
                  </Typography> */}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: '#00000066',
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '400',
                      fontSize: '25px',
                      marginTop: '30px',
                    }}
                  >
                    Total Amount
                  </Typography>
                  <Typography
                    sx={{
                      color: '#000',
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '600',
                      fontSize: '25px',
                    }}
                  >
                    {' '}
                    <CurrencyRupeeIcon sx={{ paddingTop: '4px' }} /> {total.total_amount}
                  </Typography>
                </Box>
              </Box>
            

            <ReactToPrint
            trigger={() => (
              <Button
                sx={{
                  backgroundColor: '#4CAF50',
                  color: '#fff',
                  borderRadius: '5px',
                  padding: '5px 20px',
                  marginTop: '20px',
                  ':hover': {
                    bgcolor: '#45a049',
                    color: 'white',
                  },
                }}
              >
                Print Invoice 
              </Button>
            )}
            content={() => componentRef.current}
          />

            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{
                borderRadius: '5px',
                padding: '5px 45px 5px 45px',
                float: 'right',
                marginTop: '19px',
                marginLeft:'5px'  
              }}
            >
              Cancel
            </Button>

          <Button
           variant="contained"
            sx={{
              borderRadius: '5px',
              padding: '5px 45px 5px 45px',
              float: 'right',
              marginTop: '20px',            
            }}
            onClick={updateStatus}
          >
            Submit
          </Button> 
            {/* Invoice content for printing */}
            <div style={{display: 'none'}} >
          <div ref={componentRef} >

          <Typography variant="h4" sx={{mt:'30px', mb: '30px', color: 'black', textAlign: 'center'  }}>
            INVOICE
          </Typography>
             
          <Typography gutterBottom variant="h9" sx={{ color: 'gray' }}>
            Date: {new Date(orderDate.order_date).toLocaleDateString()}
          </Typography>
                 
          <Typography gutterBottom variant="h6" sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '18px', color: 'gray', marginBottom: '20px' }}>
            ID :{orderId}
          </Typography>
      
          <Typography variant="h6">
            Name: {name.user_name}
          </Typography>

          <br/>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
            {/* Left side (Billing Address) */}
            
          <div style={{ flex: 1 }}>
            <Typography variant="h6" gutterBottom>
              Billing Address
            </Typography>
        
            <Typography variant="body1">
              Billing Address: {billing.billingAddress}
            </Typography>      
          </div>

          
          <div style={{ flex: 1 }}>
            <Typography variant="h6" gutterBottom>
              Shipping Address
            </Typography>
          
            <Typography variant="body1">
              Shipping Address: {shipping.shippingAddress}
            </Typography>         
          </div>
        </div>

          <Typography variant="h6" gutterBottom>
            Shipping Track ID: {trackId}
          </Typography>
          <br/>
          <Typography variant="h6" gutterBottom>
            Order Details
          </Typography>
          <TableContainer>
          <Table sx={{ minWidth: 100, border: '1px solid #f0f0f0' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Id</TableCell>
              <TableCell align="center">Product Name</TableCell>
              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Subtotal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && Array.isArray(data) && data.length > 0 ? (
              data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center" component="th" scope="row">
                    {row.Variant.sku}
                  </TableCell>
                  <TableCell align="center">{row.Variant.Product.product_name}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">{row.Variant.price}</TableCell>
                  <TableCell align="center">{row.subtotal}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No order details available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
        <br/>
      {/* End of Order Details Table */}
      <Typography variant="h6" sx={{ paddingTop: '4px' }}>
            Total Amount : <CurrencyRupeeIcon sx={{ paddingTop: '4px'}} /> {total.total_amount}
        </Typography>
      </div>
      
      </div>   
      </CardContent>
      )}
      </Card>
      </Container>
    </>
  );
}



