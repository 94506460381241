import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  FormControl,
  TextField,
  Input,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function AddcategoryPage() {
  const [formData, setFormData] = useState({
    category_name: '',
    description: '',
  });

  // Separate state for file
  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Check if the selected file is an SVG image
    if (file.type === 'image/svg+xml') {
      setFile(file);
    } else {
      // Clear the file field if the selected file is not an SVG image or is empty
      event.target.value = null;
      setFile(null);
      // Show an error message to the user if the selected file is not an SVG image
      alert('Please select an SVG image.');
    }
  
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Create a FormData object to handle file upload
      const formDataUpload = new FormData();
      formDataUpload.append('category_name', formData.category_name);
      formDataUpload.append('description', formData.description);
      formDataUpload.append('images', file);

      const apiUrl =
        'https://app-f130c07b-f112-44a5-8549-7445fc613c8b.cleverapps.io/api/categories/createCategory';
      const response = await axios.post(apiUrl, formDataUpload);

      console.log(response.data);
      alert('Category created successfully!');
      navigate('/dashboard/category');
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create category. Please try again later.');
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Add Category | Kadhi </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add Category
          </Typography>
        </Stack>

        <Card sx={{ minWidth: 900 }}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <div style={{ flex: '1', marginLeft: '15px', marginRight: '10px', marginBottom: '30px', display: 'flex' }}>
                <FormControl fullWidth style={{ flex: '1', marginBottom: '15px' }}>
                  <TextField
                    id="category_name"
                    name="category_name"
                    label="Category"
                    value={formData.category_name}
                    onChange={handleChange}
                    placeholder="Category"
                    fullWidth
                    required
                  />
                </FormControl>
                <FormControl fullWidth style={{ flex: '1', marginLeft: '30px' }}>
                  <Typography variant="h6">Upload Category Image (Upload svg image only)</Typography>
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    required
                    label="Upload Image"
                    accept="image/svg+xml" // Set the accept attribute to accept only SVG images
                    onChange={handleFileChange}
                  />
                </FormControl>
              </div>
              <div style={{ flex: '1', marginBottom: '15px' }}>
                <FormControl fullWidth style={{ flex: '1', marginLeft: '15px', marginBottom: '15px' }}>
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder=" Description"
                    fullWidth
                    multiline
                    rows={5}
                    required
                  />
                </FormControl>
              </div>
              <div style={{ display: 'flex' }}>
                <FormControl style={{ marginLeft: '15px', marginBottom: '15px', marginRight: '20px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ paddingLeft: '30px', paddingRight: '30px' }}
                    type="submit"
                  >
                    Save
                  </Button>
                </FormControl>
                <FormControl style={{ marginLeft: '15px', marginBottom: '15px' }}>
                  <Button
                    onClick={() => navigate('/dashboard/category')}
                    type="button"
                    variant="contained"
                    color="primary"
                    style={{ paddingLeft: '30px', paddingRight: '30px' }}
                  >
                    Cancel
                  </Button>
                </FormControl>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
