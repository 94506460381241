// RefreshToken.js

export const refreshAccessToken = async (navigate) => {
    const tokenData = JSON.parse(sessionStorage.getItem('accessToken'));
    const refreshToken = tokenData.RefreshToken;
    console.log('refresh',refreshToken)

    
    const requestBody = {
      refresh_token: refreshToken,
    };
  
    try {
      const response = await fetch('https://khadi-backend.gitdr.com/api/refreshToken/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const data = await response.json();
        const newAccessToken = data.accessToken;
  
        sessionStorage.setItem('accessToken', JSON.stringify({ accessToken: newAccessToken }));
      } else if (response.status === 401){
        sessionStorage.clear();
        navigate('/login');
      } else {
        // Handle the case when the refresh token request is not successful
        const errorData = await response.json();
        console.error('Refresh token request failed:', errorData.message || 'Refresh token request failed.');
      }
    } catch (error) {
      console.error('Refresh token request failed:', error);
    }
  };
  