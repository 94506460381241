import { Helmet } from 'react-helmet-async';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Stack,
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

export default function EditSubcategoryPage() {
  const { subcategoryid } = useParams(); 
  console.log("id: ",subcategoryid);// Assuming the subcategory ID is passed as a route parameter
  const [subcategoryName, setSubcategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const navigate = useNavigate();

  const fetchSubcategory = async () => {
    try {
      const response = await axios.get(
        `https://khadi-backend.gitdr.com/api/subcategories/${subcategoryid}`
      );
      const subcategoryData = response.data; // Assuming the response contains the subcategory data
      setSubcategoryName(subcategoryData.subcategory_name);
      setSelectedCategory(subcategoryData.category_id);
    } catch (error) {
      console.error('Error fetching subcategory:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        'https://khadi-backend.gitdr.com/api/categories'
      );
      setCategories(response.data.categories); // Assuming the response contains an array of category objects
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchSubcategory();
    fetchCategories();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'subcategoryName') {
      setSubcategoryName(value);
    } else if (name === 'selectedCategory') {
      setSelectedCategory(value);
    }
  };

  const handleCancel = () => {
    navigate('/dashboard/subcategory');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const updatedSubcategoryData = {
        subcategory_name: subcategoryName,
        category_id: selectedCategory,
      };
      await axios.put(`https://khadi-backend.gitdr.com/api/subcategories/${subcategoryid}`, updatedSubcategoryData);
      console.log('Subcategory updated successfully');
      navigate('/dashboard/subcategory'); // Navigating back to the subcategory page after successful submission
    } catch (error) {
      console.log('Error submitting form:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Edit Sub Category | Kadhi </title>
      </Helmet>
      <Container>
        <Card sx={{ minWidth: 500 }}>
          <CardContent>
            <Typography>
              <FormControl>
                <TextField
                  name="selectedCategory"
                  label="Category"
                  select
                  value={selectedCategory}
                  onChange={handleChange}
                  sx={{ width: '280px', mb: 3 }}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.category_id}>
                      {category.category_name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Typography>
            <form onSubmit={handleFormSubmit}>
              <Stack spacing={3}>
                <TextField
                  name="subcategoryName"
                  label="Subcategory Name"
                  value={subcategoryName}
                  onChange={handleChange}
                  required
                />
                <Stack direction="row" justifyContent="space-between">
                  <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    Update
                  </Button>
                </Stack>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
