import { Helmet } from 'react-helmet-async';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Stack,
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function AddSubcategoryPage() {
  const [subcategoryName, setSubcategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const navigate = useNavigate();

  const fetchCategory = async () => {
    try {
      const response = await axios.get(
        'https://khadi-backend.gitdr.com/api/categories'
      );
      setCategories(response.data.categories); // Assuming the response contains an array of category objects
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'subcategoryName') {
      setSubcategoryName(value);
    } else if (name === 'selectedCategory') {
      setSelectedCategory(value);
    }
  };

  const handleCancel = () => {
    navigate('/dashboard/subcategory');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
     
      const data = {
        category_id: selectedCategory,
        subcategory_name: subcategoryName,
      };
      console.log(data);
      const response = await axios.post(
        'https://khadi-backend.gitdr.com/api/subcategories/createSubcategory',
        data,
        {
          headers: {
            'Content-Type': 'Application/json',
          },
        }
      );

      console.log(response.data);
      alert('Success');
      navigate('/dashboard/subcategory');
    } catch (error) {
      console.log('Error sending data to server:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Add Sub Category | Kadhi </title>
      </Helmet>
      <Container>
        <Card sx={{ minWidth: 500 }}>
          <CardContent>
  
                <Typography>
                  <FormControl>
                    <TextField
                      name="selectedCategory"
                      label="Category"
                      select
                      value={selectedCategory}
                      onChange={handleChange}
                      sx={{ width: '280px', mb:3 }}
                    >
                      {categories.map((category) => (
                        <MenuItem key={category.id} value={category.category_id}>
                          {category.category_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Typography>
                <form onSubmit={handleFormSubmit}>
              <Stack spacing={3}>
                <TextField
                  name="subcategoryName"
                  label="Subcategory Name"
                  value={subcategoryName}
                  onChange={handleChange}
                  required
                />
                <Stack direction="row" justifyContent="space-between">
                  <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </Stack>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
