import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  FormControl,
  TextField,
  Input,
  Button,
  CardMedia,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function EditCategoryPage() {
  const { categoryid } = useParams(); // Get the categoryid from the URL params
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = useState({
    category_name: '',
    description: '',
    category_img_url: '', // Add this field to hold the image URL
  });

  // Separate state for the image file
  const [file, setFile] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    console.log('categoryid:', categoryid);
    
    const fetchCategory = async () => {
      try {
        if (categoryid) {
          const response = await axios.get(
            `https://khadi-backend.gitdr.com/api/categories/${categoryid}`
          );
          console.log('get data',response.data);
          console.log('categoryname', response.data.category.category_name);
          if (!response.data) {
            setError('Category data not found.');
            setIsLoading(false);
            return;
          }

          setCategoryData({
            category_name: response.data.category.category_name,
            description: response.data.category.description,
            category_img_url: response.data.category.category_img_url,
          });
          console.log('name',response.category_name);
          // Update the file state if there is an image URL in the response
          if (response.data.category.category_img_url) {
            setFile(response.data.category.category_img_url);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setError(error.message || 'Failed to fetch category data');
        setIsLoading(false);
      }
    };

    fetchCategory();
  }, [categoryid]);


  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCategoryData((prevCategoryData) => ({
      ...prevCategoryData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Check if the selected file is an SVG image
    if (file?.type === 'image/svg+xml') {
      setFile(URL.createObjectURL(file));
      setCategoryData((prevData) => ({
        ...prevData,
        category_img_url: URL.createObjectURL(file), // Set the new image URL if a file is selected
      }));
    } else {
      // Clear the file field if the selected file is not an SVG image or is empty
      event.target.value = null;
      setFile(null);
      // Show an error message to the user if the selected file is not an SVG image
      alert('Please select an SVG image.');
    }
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const updatedCategoryData = {
        ...categoryData,
        // Include the categoryid in the updated data
        id: categoryid,
      };
    
      const apiUrl = `https://khadi-backend.gitdr.com/api/categories/${categoryid}`;
      await axios.put(apiUrl, updatedCategoryData);
      alert('Category updated successfully!');
   
      navigate('/dashboard/category');
      window.location.reload();
     
    } catch (error) {
      setError(error.message || 'Failed to update category. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Container>
        <Typography variant="h4" gutterBottom>
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>Dashboard: Edit Category | Kadhi</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Category
          </Typography>
        </Stack>

        {error && (
          <Typography variant="body1" color="error" gutterBottom>
            {error}
          </Typography>
        )}

        <Card sx={{ minWidth: 600 }}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth style={{ marginBottom: '15px' }}>
                <TextField
                  id="category_name"
                  name="category_name"
                  label="Category"
                  value={categoryData.category_name}
                  onChange={handleChange}
                  placeholder="Category"
                  fullWidth
                  required
                />
              </FormControl>

              <FormControl fullWidth style={{ marginBottom: '15px' }}>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  value={categoryData.description}
                  onChange={handleChange}
                  placeholder="Description"
                  fullWidth
                  multiline
                  rows={5}
                  required
                />
              </FormControl>

              <FormControl fullWidth style={{ marginBottom: '15px' }}>
  <Typography variant="h6">Current Category Image</Typography>
  {categoryData.category_img_url && (
    <CardMedia
      component="img"
      src={categoryData.category_img_url}
      alt="Current Category Image"
      sx={{ maxHeight: 200, objectFit: 'contain', mt: 1 }}
    />
  )}
  <Input
    type="file"
    id="image"
    name="image"
    required={!categoryData.category_img_url} 
    accept="image/svg+xml" // Set the accept attribute to accept only SVG images
    onChange={handleFileChange}
  />
</FormControl>


              <div style={{ display: 'flex' }}>
                <FormControl style={{ marginLeft: '15px', marginBottom: '15px', marginRight: '20px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ paddingLeft: '30px', paddingRight: '30px' }}
                    type="submit"
                  >
                    Update
                  </Button>
                </FormControl>
                <FormControl style={{ marginLeft: '15px', marginBottom: '15px' }}>
                  <Button
                    onClick={() => navigate('/dashboard/category')}
                    variant="contained"
                    color="primary"
                    style={{ paddingLeft: '30px', paddingRight: '30px' }}
                  >
                    Cancel
                  </Button>
                </FormControl>
              </div>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
