import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
import { AppCurrentVisits, AppWebsiteVisits } from '../sections/@dashboard/app';
import axios from 'axios';
//import { useLocation } from 'react-router-dom';

export default function DashboardAppPage() {
  
  const baseURL = 'https://khadi-backend.gitdr.com/api/reports/orderReport';
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Retrieve data from sessionStorage
  const storedAccessToken = sessionStorage.getItem('accessToken');
  //const storedrefreshToken = sessionStorage.getItem('refreshToken');
  const storedUserName = sessionStorage.getItem('userName');
  const storedEmail = sessionStorage.getItem('email');




  // Use the stored data
  console.log('Access Token:', storedAccessToken);

 
  console.log('User Name:', storedUserName);
  console.log('Email:', storedEmail);
  
  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${storedAccessToken}`, 
          },
        });
        const responseData = response.data.data;
     
        setRows(responseData);
        setIsLoading(false);
      } catch (error) {
      
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const monthColors = {
    January: '#ff0000',
    February: '#ff8000',
    March: '#ffff00',
    April: '#80ff00',
    May: '#00ff00',
    June: '#00ff80',
    July: '#00ffff',
    August: '#0080ff',
    September: '#0000ff',
    October: '#8000ff',
    November: '#ff00ff',
    December: '#ff0080',
  };

  const chartData = rows.map((row) => ({
    label: `${row.month}`,
    value: row.totalOrders,
    color: monthColors[row.month],
  }));

  const barChartData = rows.map((row) => ({
    label: `${row.month}/${row.year}`,
    value: row.totalAmount,
  }));

  const redColor = '#ff0000';
  const barChartWithRedColor = barChartData.map((data) => ({
    ...data,
    color: redColor,
  }));

  return (
    <>
      <Helmet>
        <title>Dashboard | kadhi</title>
      </Helmet>

      <Container maxWidth="xl">
        <div>
          <h1>Welcome to the Dashboard</h1>

          {/* {storedAccessToken && <p>Access Token: {storedAccessToken}</p>} */}
        </div>
        <Grid container spacing={3}>
          <Grid item xs={10} md={6} lg={8}>
            <AppWebsiteVisits
              title="Total Orders and Amount"
              chartLabels={rows.map((row) => `${row.month}/${row.year}`)}
              chartData={[
                {
                  name: 'Total Orders',
                  type: 'line',
                  fill: 'solid',
                  data: rows.map((row) => row.totalOrders),
                },
                {
                  name: 'Total Amount',
                  type: 'area',
                  fill: 'gradient',
                  data: rows.map((row) => row.totalAmount),
                },
                {
                  name: 'Total Amount',
                  type: 'bar',
                  data: barChartWithRedColor.map((data) => data.value),
                  color: redColor,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Total Orders"
              chartData={chartData}
              chartColors={chartData.map((data) => data.color)}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
