import React from 'react';
import { useParams } from 'react-router-dom';
import { useState , useEffect } from 'react';
import axios from 'axios';
import { Typography,
  Card,
  CardContent,
  Container
   } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import NetworkHandler from 'src/utils/NetworkHandler';
function ProductviewDetailsPage() {
  const { variant_id } = useParams(); 
  const [variant, setVariant] = useState(null); 

  const fetchVariantDetails = async () => {
    try {
      const response = await NetworkHandler.makeGetRequest(
        `/variants/getVariant/${variant_id}`
      );
      console.log("data",response.data.data);
      setVariant(response.data.data.variants[0]); 
    } catch (error) {
      console.error('Error fetching variant details:', error);
    }
  };
  
  useEffect(() => {
    fetchVariantDetails();
  }, [variant_id]);
  
  function formatParameters(parameters) {
    try {
      const parsedParameters = JSON.parse(parameters);

      const formattedParameters = Object.keys(parsedParameters)
        .map((key) => `${key}: ${parsedParameters[key]}`)
        .join(',');
  
      return formattedParameters;
    } catch (error) {
      console.error('Error parsing parameters:', error);
      return 'Invalid Parameters';
    }
  }
  
    return (
      <div>
        <Container>
         <Typography variant="h4">Variant Details</Typography>
         <Card sx={{ mb: 3 }}>
          <CardContent>
      
      {variant ? (
        <>
         
            <div>
            {variant.Images.length > 0 && (
              <div style={{ marginBottom: '20px'  }}>
                <img
                  src={variant.Images[0].image_url}
                  alt="Main Image"
                  style={{ maxWidth: '400px', maxHeight: '300px' }}
                />
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              {variant.Images.slice().map((image, index) => (
                <img
                  key={index}
                  src={image.image_url}
                  alt={`image ${index + 1}`}
                  style={{
                   
                    maxWidth: '150px',
                    maxHeight: '100px',
                  }}
                />
            ))}
          </div>
          </div>
          <div style={{ marginTop: '20px' }}> 

       
          <Typography>Variant Name: {variant.variant_name}</Typography>
          <Typography>Parameters:</Typography>
              <div> {formatParameters(variant.parameters)}
              </div>
          <Typography>Quantity: {variant.quantity}</Typography>
          <Typography>Price: {variant.price}</Typography>
          {/* Add other details you want to display */}
          </div>
        
        </>
      ) : (
        <CircularProgress />
      )}
      </CardContent>
      </Card>
      </Container>
      </div>
    );
  }
  

export default ProductviewDetailsPage
