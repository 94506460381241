import { Helmet } from 'react-helmet-async';
// @mui
import {
  Popover,
  MenuItem,
  IconButton,
  Button,
  // Modal,
  Container,
  Stack,
  Typography,
  Card,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardContent,
  TableContainer,
  // Box,
  // TextField,
  TablePagination,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // useMediaQuery,
} from '@mui/material';


// loader,hooks,axios
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import axios from 'axios';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

import { useNavigate } from 'react-router-dom';

// import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/order';
// mock
// import POSTS from '../_mock/category';
// import { set } from 'lodash';

export default function BannerPage() {
  const baseURL = 'https://khadi-backend.gitdr.com/api/banners/?page=1&pagesize=50';
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);



  const navigate = useNavigate();

  const handleOpenMenu = (event, bannerId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(bannerId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  //date format
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toISOString().slice(0, 10).replace('T', ' ');
    return formattedDate;
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseURL).then((response) => {
          console.log(response.data);
          console.log(response.data.data.banners);
          setRows(response.data.data.banners);
          setIsLoading(false);
        });
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleDeleteClick = (bannerId) => {
    setItemToDelete(bannerId);
    setDeleteDialogOpen(true);
    handleCloseMenu();
  };

  const handleDeleteConfirmation = async () => {
    try {
      const apiUrl = `https://khadi-backend.gitdr.com/api/banners/${itemToDelete}`;
      await axios.delete(apiUrl);
      setRows((prevRows) => prevRows.filter((row) => row.banner_id !== itemToDelete));
      alert('Banner deleted successfully!');
    } catch (error) {
      console.error('Failed to delete Banner:', error);
      alert('Failed to delete Banner. Please try again later.');
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleCancelConfirmation = () => {
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Banners | Kadhi </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Banners
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => navigate('/dashboard/BannerPage/BannerAdd')}

          >
            New Banner
          </Button>


        </Stack>

        <Scrollbar>
          <Card sx={{ minWidth: 900 }}>
            <CardContent>
              {isLoading ? ( // Display the loader while data is being fetched
                <CircularProgress />
              ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">ID</TableCell>
                          <TableCell align="left">Banner Image</TableCell>
                          <TableCell align="left">Link</TableCell>
                          <TableCell align="left">Expire Date</TableCell>
                          <TableCell align="left"> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.banner_id}>
                              <TableCell align="left">{row.banner_id}</TableCell>
                              <TableCell align="left">
                                {' '}
                                <img src={row.banner_img_url} alt="Icon" />{' '}
                              </TableCell>
                              <TableCell align="left">{row.link}</TableCell>
                              <TableCell align="left">{formatDate(row.expire_date)}</TableCell>
                              <TableCell align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) => handleOpenMenu(event, row.banner_id)}
                                >
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                                <Popover
                                  open={selectedRow !== null && selectedRow === row.banner_id}
                                  anchorEl={anchorEl}
                                  onClose={handleCloseMenu}
                                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                  PaperProps={{
                                    sx: {
                                      p: 1,
                                      width: 140,
                                      '& .MuiMenuItem-root': {
                                        px: 1,
                                        typography: 'body2',
                                        borderRadius: 0.75,
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem onClick={() => navigate(`/dashboard/BannerPage/BannerEdit/${row.banner_id}`)}>
                                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    sx={{ color: 'error.main' }}
                                    onClick={() => {
                                      handleDeleteClick(row.banner_id);
                                    }}
                                  >
                                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                    Delete
                                  </MenuItem>
                                </Popover>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>

                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}
            </CardContent>
          </Card>
        </Scrollbar>
      </Container>

      <Dialog open={isDeleteDialogOpen} onClose={handleCancelConfirmation}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this Banner?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
