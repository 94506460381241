import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Popover,
  MenuItem,
  IconButton,
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  Paper,
  Table,
  Input,
  FormControl,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TableContainer,
  TablePagination,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { useNavigate, Link,useLocation } from 'react-router-dom';
import axios from 'axios';
import NetworkHandler from 'src/utils/NetworkHandler';

export default function CategoryPage() {

  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(null);
  const Navigate = useNavigate();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userPosts, setUserPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)
 
  const handleOpenMenu = (categoryId) => (event) => {
    setSelectedCategoryId(categoryId);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedCategoryId(null);
    setOpen(null);
  };
  useEffect(() => {

  
    const fetchData = async () => {    
      
      try {  
        
        const allCategory = []; 
        const response = await NetworkHandler.makeGetRequest(`/categories?page=${page + 1}&pagesize=${rowsPerPage}`);
        const categories = response.data.categories;
        allCategory.push(...categories);
        setPageCount(response.data.totalPages)
        setRows(response.data.totalPages * rowsPerPage)
        setUserPosts(allCategory);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
   
    
    fetchData();
  
  },[page, rowsPerPage]);
  
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteClick = (categoryId) => {
    handleCloseMenu();
    setItemToDelete(categoryId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      // const apiUrl = `https://khadi-backend.gitdr.com/api/categories/${itemToDelete}`;
      
      await NetworkHandler.makeDeleteRequest(`categories/${itemToDelete}`)

      // await axios.delete(apiUrl);

      setRows((prevRows) => prevRows.filter((row) => row.category_id !== itemToDelete));

      alert('Category deleted successfully!');
    } catch (error) {
      console.error('Failed to delete category:', error);
      alert('Failed to delete category. Please try again later.');
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleCancelConfirmation = () => {
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Category | Kadhi </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Category
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => Navigate('/dashboard/category/addcategory')}>
            New Category
          </Button>
        </Stack>
        <Scrollbar>
          <Card sx={{ minWidth: 900 }}>
            <CardContent>
           
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">ID</TableCell>
                          <TableCell align="left">Category</TableCell>
                          <TableCell align="left">Category Image</TableCell>
                          <TableCell align="left">Description</TableCell>
                          <TableCell align="left"> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userPosts.map((row) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.category_id}>
                            <TableCell align="left">{row.category_id}</TableCell>
                            <TableCell align="left">{row.category_name}</TableCell>
                            <TableCell align="left">
                              <img src={row.category_img_url} alt="Icon" style={{ width: '25px' }} />
                            </TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={handleOpenMenu(row.category_id)}
                              >
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                            <Popover
                              open={selectedCategoryId === row.category_id}
                              anchorEl={open}
                              onClose={handleCloseMenu}
                              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                              PaperProps={{
                                sx: {
                                  p: 1,
                                  width: 140,
                                  '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                  },
                                },
                              }}
                            >
                              <MenuItem
                                component={Link}
                                to={`/dashboard/category/editcategory/${row.category_id}`}
                                onClick={handleCloseMenu}
                              >
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>
                              <MenuItem
                                sx={{ color: 'error.main' }}
                                onClick={() => {
                                  handleDeleteClick(row.category_id);
                                  handleCloseMenu();
                                }}
                              >
                                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                Delete
                              </MenuItem>
                            </Popover>
                            <Dialog open={isDeleteDialogOpen} onClose={handleCancelConfirmation}>
                              <DialogTitle>Confirm Deletion</DialogTitle>
                              <DialogContent
                                PaperProps={{
                                  sx: {
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                  },
                                }}
                              >
                                <DialogContentText>
                                  Are you sure you want to delete this category?
                                </DialogContentText>
                                <DialogActions>
                                  <Button onClick={handleCancelConfirmation} color="primary">
                                    Cancel
                                  </Button>
                                  <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
                                    Delete
                                  </Button>
                                </DialogActions>
                              </DialogContent>
                            </Dialog>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}
            </CardContent>
          </Card>
        </Scrollbar>
      </Container>
    </>
  );
}
